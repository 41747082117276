<template>
  <div>
    <Navigation/>
    <div style="justify-content: center; display: flex;">
      <v-container style="margin: 20px; margin-bottom: 60px; max-width: 90%;">
        <v-form v-model="form" @submit.prevent="onSubmit">
          <v-row dense>
            <v-col cols="12" md="6">
              <v-text-field v-model="profile['first_name']" :label="selectedLanguage['profile_form_name_1']" :rules="[required]"></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field v-model="profile['last_name']" :label="selectedLanguage['profile_form_name_2']"></v-text-field>
            </v-col>
            <!-- <v-col cols="12" md="6">
              <v-text-field v-model="profile['email']" :label="selectedLanguage['profile_form_email']" disabled></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field v-model="profile['password']" :label="selectedLanguage['profile_form_password']" type="password" disabled></v-text-field>
            </v-col> -->
            <v-col cols="12" md="6">
              <v-text-field v-model="profile['phone']" :label="selectedLanguage['profile_form_phone']" v-mask="'+## ## #####-####'"></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-combobox v-model="profile['country']" :items="countries" :label="selectedLanguage['profile_form_country']"/>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field @input="formatCPF" v-if="profile.country=='Brazil' || profile.country=='Brasil'" v-model="profile['civil_id']" :rules="[rules.civil_id]" :label="selectedLanguage['profile_form_civil_id']" v-mask="'###.###.###-##'"/>
              <v-text-field @input="formatCPF" v-else v-model="profile['civil_id']" :rules="[rules.civil_id]" :label="selectedLanguage['profile_form_civil_id']"/>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field v-if="profile.country=='Brazil'" v-model="profile['work_id']" :label="selectedLanguage['profile_form_work_id']" v-mask="'#########-#'"/>
              <v-text-field v-else v-model="profile['work_id']" :label="selectedLanguage['profile_form_work_id']"/>
            </v-col>
            <v-col cols="12" md="6">
              <v-select v-model="profile['language']" :items="languages" :label="selectedLanguage['profile_form_language']"/>
              <!-- <v-combobox v-model="profile['language']" :items="languages" :label="selectedLanguage['profile_form_language']"/> -->
            </v-col>
            <v-btn :disabled="!form" :loading="loading" block color="primary" size="large" type="submit" variant="elevated">{{ this.selectedLanguage['profile_form_btn_save'] }}</v-btn>
            <v-alert v-if="profileMessage" dense border="top" color="error" dark>
              {{ profileMessage }}
            </v-alert>
          </v-row>
        </v-form>  
      </v-container>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import Navigation from './Navigation'
  import { portuguese } from '../languages/pt-br.js'
  import { english } from '../languages/en'
  
  export default {
    name: 'Profile',
    computed: {
      ...mapGetters(['profile']),
      selectedLanguage() {
          if (this.profile['language'] === 'en') {
            return english;
          } else {
            return portuguese;
          }
        },
      // rules() {
      //   return {
      //     required: value => !!value || this.register_rules_txt_1,
      //     password: [
      //       value => !!value || this.register_rules_txt_2,
      //       (value) => (value && /[^A-Za-z0-9]/.test(value)) || this.register_rules_txt_4,
      //       (value) => (value && value.length > 9) || this.register_rules_txt_5,
      //     ],
      //     email: value => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value) || this.register_rules_txt_6,
      //     civil_id: value => this.validarCPF(value) || this.register_rules_txt_7
      //   };
      // },
      rules() {
        return {
          required: value => !!value || "Campo obrigatório",
          password: [
            value => !!value || "Por favor, digite sua senha.",
            (value) => !!value || "Por favor, digite sua senha.",
            (value) => (value && /[^A-Za-z0-9]/.test(value)) || "É necessário possuir ao menos um caractere especial",
            (value) => (value && value.length > 9) || "Mínimo 10 caracteres",
          ],
          email: value => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value) || "O e-mail deve ser válido",
          civil_id: value => {
            if (value !== undefined && value !== '') {
              return this.validarCPF(value) || "CPF inválido";
            }
            return true;
          }
        };
      },
    },
    mounted: async function(){
      await this.initRegisterRules()
    },
    components: { Navigation },
    // created(){
    //   if(this.profile['language'] == 'en'){
    //     this.selectedLanguage = english
    //   } else {
    //     this.selectedLanguage = portuguese
    //   }

    //   console.log('language: ',this.selectedLanguage)
    // },
    data: () => ({
      loading: false,
      form: false,
      profileMessage: undefined,
      languages: [
        'pt-br',
        'en'
      ],
      register_rules_txt_1: "Campo obrigatório",
      register_rules_txt_2: "Porfavor digite sua senha.",
      register_rules_txt_3: "Porfavor digite sua senha.",
      register_rules_txt_4: "É necessario possuir ao menos um caractere especial",
      register_rules_txt_5: "Mínimo 10 caracteres",
      register_rules_txt_6: "O e-mail deve ser válido",
      register_rules_txt_7: "CPF inválido",
      // rules: {
      //   required: value => !!value || "Campo obrigatório",
      //   password: [
      //     value => !!value || "Porfavor digite sua senha.",
      //     (value) => !!value || "Porfavor digite sua senha.",
      //     // (value) => (value && /\d/.test(value)) || 'At least one digit',
      //     // (value) => (value && /[A-Z]{1}/.test(value)) || 'At least one capital latter',
      //     (value) => (value && /[^A-Za-z0-9]/.test(value)) || "É necessario possuir ao menos um caractere especial",
      //     (value) => (value && value.length > 9 ) || "Mínimo 10 caracteres",
      //   ],
      //   // equalPassword: value => value===this.password || 'Please reenter password',
      //   email: value => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value) || "O e-mail deve ser válido",
      //   civil_id: value => this.validarCPF(value) || "CPF inválido"
      // },
      countries: [
        'Afghanistan',
        'Albania',
        'Algeria',
        'Andorra',
        'Angola',
        'Antigua and Barbuda',
        'Argentina',
        'Armenia',
        'Austria',
        'Azerbaijan',
        'Bahrain',
        'Bangladesh',
        'Barbados',
        'Belarus',
        'Belgium',
        'Belize',
        'Benin',
        'Bhutan',
        'Bolivia',
        'Bosnia and Herzegovina',
        'Botswana',
        'Brazil',
        'Brunei',
        'Bulgaria',
        'Burkina Faso',
        'Burundi',
        'Cabo Verde',
        'Cambodia',
        'Cameroon',
        'Canada',
        'Central African Republic',
        'Chad',
        'Channel Islands',
        'Chile',
        'China',
        'Colombia',
        'Comoros',
        'Congo',
        'Costa Rica',
        "Côte d'Ivoire",
        'Croatia',
        'Cuba',
        'Cyprus',
        'Czech Republic',
        'Denmark',
        'Djibouti',
        'Dominica',
        'Dominican Republic',
        'DR Congo',
        'Ecuador',
        'Egypt',
        'El Salvador',
        'Equatorial Guinea',
        'Eritrea',
        'Estonia',
        'Eswatini',
        'Ethiopia',
        'Faeroe Islands',
        'Finland',
        'France',
        'French Guiana',
        'Gabon',
        'Gambia',
        'Georgia',
        'Germany',
        'Ghana',
        'Gibraltar',
        'Greece',
        'Grenada',
        'Guatemala',
        'Guinea',
        'Guinea-Bissau',
        'Guyana',
        'Haiti',
        'Holy See',
        'Honduras',
        'Hong Kong',
        'Hungary',
        'Iceland',
        'India',
        'Indonesia',
        'Iran',
        'Iraq',
        'Ireland',
        'Isle of Man',
        'Israel',
        'Italy',
        'Jamaica',
        'Japan',
        'Jordan',
        'Kazakhstan',
        'Kenya',
        'Kuwait',
        'Kyrgyzstan',
        'Laos',
        'Latvia',
        'Lebanon',
        'Lesotho',
        'Liberia',
        'Libya',
        'Liechtenstein',
        'Lithuania',
        'Luxembourg',
        'Macao',
        'Madagascar',
        'Malawi',
        'Malaysia',
        'Maldives',
        'Mali',
        'Malta',
        'Mauritania',
        'Mauritius',
        'Mayotte',
        'Mexico',
        'Moldova',
        'Monaco',
        'Mongolia',
        'Montenegro',
        'Morocco',
        'Mozambique',
        'Myanmar',
        'Namibia',
        'Nepal',
        'Netherlands',
        'Nicaragua',
        'Niger',
        'Nigeria',
        'North Korea',
        'North Macedonia',
        'Norway',
        'Oman',
        'Pakistan',
        'Panama',
        'Paraguay',
        'Peru',
        'Philippines',
        'Poland',
        'Portugal',
        'Qatar',
        'Réunion',
        'Romania',
        'Russia',
        'Rwanda',
        'Saint Helena',
        'Saint Kitts and Nevis',
        'Saint Lucia',
        'Saint Vincent and the Grenadines',
        'San Marino',
        'Sao Tome & Principe',
        'Saudi Arabia',
        'Senegal',
        'Serbia',
        'Seychelles',
        'Sierra Leone',
        'Singapore',
        'Slovakia',
        'Slovenia',
        'Somalia',
        'South Africa',
        'South Korea',
        'South Sudan',
        'Spain',
        'Sri Lanka',
        'State of Palestine',
        'Sudan',
        'Suriname',
        'Sweden',
        'Switzerland',
        'Syria',
        'Taiwan',
        'Tajikistan',
        'Tanzania',
        'Thailand',
        'The Bahamas',
        'Timor-Leste',
        'Togo',
        'Trinidad and Tobago',
        'Tunisia',
        'Turkey',
        'Turkmenistan',
        'Uganda',
        'Ukraine',
        'United Arab Emirates',
        'United Kingdom',
        'United States',
        'Uruguay',
        'Uzbekistan',
        'Venezuela',
        'Vietnam',
        'Western Sahara',
        'Yemen',
        'Zambia',
        'Zimbabwe'
      ],
    }),
    methods: {
      ...mapActions(['updateProfile','refresh', 'getProfile']),
      async onSubmit () {
        this.profileMessage = undefined
        if (!this.form) return
        this.loading = true
        this.profile['password'] = ''
        const res = await this.updateProfile(this.profile)
        await this.refresh()
        await this.getProfile()
        if(res!=true){
          // console.log('Profile updated')
          // if(await this.refresh()){
          //   console.log('refresh token done!')
          // }else{
          //   console.log('Problem with refresh token')
          // }
          this.profileMessage = res
        }
        this.loading = false
      },
      formatCPF() {
        let value = this.profile['civil_id'].replace(/\D/g, '')

        if (value.length <= 11) {
          value = value.replace(/(\d{3})(\d)/, '$1.$2');
          value = value.replace(/(\d{3})(\d)/, '$1.$2');
          value = value.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
        }

        // Atualiza o valor do v-model
        this.profile.civil_id = value;
      },
      validarCPF(cpf) {
        // Remove caracteres não numéricos
        cpf = cpf.replace(/[^\d]+/g, '');

        // Verifica se o CPF tem 11 dígitos
        if (cpf.length !== 11) return false;

        // Verifica se todos os dígitos são iguais (caso inválido)
        if (/^(\d)\1+$/.test(cpf)) return false;

        // Validação do primeiro dígito verificador
        let soma = 0;
        for (let i = 0; i < 9; i++) {
          soma += parseInt(cpf.charAt(i)) * (10 - i);
        }

        let resto = (soma * 10) % 11;
        if (resto === 10 || resto === 11) resto = 0;
        if (resto !== parseInt(cpf.charAt(9))) return false;

        // Validação do segundo dígito verificador
        soma = 0;
        for (let i = 0; i < 10; i++) {
          soma += parseInt(cpf.charAt(i)) * (11 - i);
        }

        resto = (soma * 10) % 11;
        if (resto === 10 || resto === 11) resto = 0;
        if (resto !== parseInt(cpf.charAt(10))) return false;

        return true;
      },
      initRegisterRules(){
        this.register_rules_txt_1 = this.selectedLanguage['register_rules_txt_1']
        this.register_rules_txt_2 = this.selectedLanguage['register_rules_txt_2']
        this.register_rules_txt_3 = this.selectedLanguage['register_rules_txt_3']
        this.register_rules_txt_4 = this.selectedLanguage['register_rules_txt_4']
        this.register_rules_txt_5 = this.selectedLanguage['register_rules_txt_5']
        this.register_rules_txt_6 = this.selectedLanguage['register_rules_txt_6']
        this.register_rules_txt_7 = this.selectedLanguage['register_rules_txt_7']
        this.countries = this.selectedLanguage['countries_list']

        // this.rules = {
        //   required: value => !!value || this.register_rules_txt_1,
        //   password: [
        //     value => !!value || this.register_rules_txt_2,
        //     (value) => !!value || this.register_rules_txt_3,
        //     // (value) => (value && /\d/.test(value)) || 'At least one digit',
        //     // (value) => (value && /[A-Z]{1}/.test(value)) || 'At least one capital latter',
        //     (value) => (value && /[^A-Za-z0-9]/.test(value)) || this.register_rules_txt_4,
        //     (value) => (value && value.length > 9 ) || this.register_rules_txt_5,
        //   ],
        //   // equalPassword: value => value===this.password || 'Please reenter password',
        //   email: value => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value) || this.register_rules_txt_6,
        //   civil_id: value =>
        //   // this.validarCPF(value) || this.register_rules_txt_7
        //   {
        //     if (value !== undefined && value !== '') {
        //       return this.validarCPF(value) || this.register_rules_txt_7
        //     }
        //     return true
        //   }
        // }
      },
      required (v) {
        return !!v || 'Field is required'
      }
    },
    // directives: {
    //   mask: {
    //     bind(el) {
    //       el.addEventListener('input', () => {
    //         let value = el.value.replace(/\D/g, ''); // Remove todos os caracteres não numéricos

    //         // Formatação CPF ###.###.###-##
    //         if (value.length <= 11) {
    //           value = value.replace(/(\d{3})(\d)/, '$1.$2');
    //           value = value.replace(/(\d{3})(\d)/, '$1.$2');
    //           value = value.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
    //         }

    //         el.value = value;
    //         el.dispatchEvent(new Event('input'));
    //       });
    //     },
    //   },
    // },
  }
</script>